import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {Link} from "react-router-dom";
import {useTheme, useMediaQuery} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    ffBarlow: {
        fontFamily: "barlow",
        fontWeight: "bold"
    },
    hero: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        margin: theme.spacing(0, 10, 0, 10),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    signupForm: {
        backgroundColor: '#fff',
        borderRadius: 4,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(4),
        },
        useCases: {
            backgroundColor: theme.palette.primary.light,
            padding: theme.spacing(8, 0, 6),
        },
        card: {
            height: '100%',
            backgroundColor: 'red'
        },
        imgRight: {
            height: '30vh'
        },
        intro: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline'
        }
    },

}));

export default function LandingPage() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleLinkClick = (goalId) => {
        window.fathom && window.fathom.trackGoal(goalId, 0);
    };

    return (
        <Box style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
        }}>
            <Box style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline',
                padding: isMobile ? '16px' : '0'
            }}>
                <Typography className={classes.ffBarlow} variant="h5" align="left" color="textPrimary" gutterBottom>
                    Tech by Choice
                </Typography>
                <Typography className={classes.ffBarlow} variant="h2" align="left" color="textPrimary" gutterBottom>
                    Career Finder
                </Typography>
                <Typography variant="h6" align="left" color="textSecondary" component="p">
                    One quiz to help you jump start your tech journey
                </Typography>
                <Box style={{display: 'flex', marginTop: '32px'}}>
                    <Button component={Link} variant="outlined" style={{marginRight: '32px'}} color="primary"
                            to="/role">
                        View Roles
                    </Button>
                    <Button component={Link} onClick={handleLinkClick('')} variant="contained" color="primary" to="/quiz">
                        Take Quiz
                    </Button>
                </Box>
            </Box>
            {!isMobile && (
                <Box>
                    <img style={{height: '40vw'}}
                         src='https://uploads-ssl.webflow.com/5fc123904bcd576087dd38e2/64bb6f89639b1ab0257bb18b_tbc-quiz-logo.png'/>
                </Box>
            )}
        </Box>
    )
}
