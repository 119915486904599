import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTheme, useMediaQuery } from "@mui/material";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '20px',
        boxShadow: 'none'
    },
    logo: {
        height: '64px',
        marginRight: '16px'
    },
    name: {
        color: 'black',
        textDecoration: 'none',
        fontWeight: 'bold',
        fontFamily: "barlow",
    },
    navLink: {
        textDecoration: 'none',
        fontWeight: 'strong',
        color: '#000',
        transition: 'all 1s ease-out',
        '&:hover': {
            color: '#5f5b5b'
        }
    }
}));

function TopNavBar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

   const handleLinkClick = (goalId) => {
    window.fathom && window.fathom.trackGoal(goalId, 0);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className={classes.wrapper} color='transparent'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            <Link to='/'
                  onClick={() => handleLinkClick('DCAO2CJP')}
                  style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none'
            }}>
              <img
                className={classes.logo}
                src='https://uploads-ssl.webflow.com/5fc123904bcd576087dd38e2/6071eb46472b5c02f7dbd662_tbc-logo.svg'
                alt='Tech by Choice Logo'
                loading="lazy" />
                {!isMobile &&(<>
                    <Typography
                  className={classes.name}
                variant="h6"
                noWrap
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Tech by Choice
              </Typography>
                </>)}
            </Link>
          <div style={{ flexGrow: 1 }} />
          <Link onClick={() => handleLinkClick('3RW1ZRI1')} className={classes.navLink} style={{marginRight: '40px'}} component="button" to='/quiz' >
              Take Career Quiz
          </Link>
          <Link onClick={() => handleLinkClick('YF8A2GSR')} className={classes.navLink} component="button" to='/role' >
              Roles
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
    </Box>
  );
}

export default TopNavBar;
