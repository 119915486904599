import React from 'react';
import Chip from "@material-ui/core/Chip";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";


const useStyles = makeStyles(theme => ({
    // resultsWrapper: {
    //    padding: theme.spacing(5)
    // },
    ffBarlow: {
      fontFamily: "barlow",
        fontWeight: "bold"
    },
    pill: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    header: {
        paddingLeft: '10vw',
        paddingRight: '10vw',
        marginBottom: theme.spacing(9)
    },
    details: {
        marginBottom: theme.spacing(3),
        textAlign: 'left'
    },
    roles: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    alertWrapper: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

function RoleDetails({ role, role_id }) {
  const classes = useStyles();

  return (
      <>
        {role && (
          <div style={{
              paddingLeft: '56px',
              paddingRight: '56px'
          }}>
              <Typography variant="h3" className={classes.ffBarlow} component="h2">
                {role.name}
              </Typography>
              <Box className={role_id ? classes.alertWrapper : ""} justify="center">
                <Alert severity="info" style={{marginTop: '1vh',marginBottom: '3vh', textAlign: 'left'}}>
                  <AlertTitle>Looking for real insight on what it's like to work in tech?</AlertTitle>
                  <Link target="_blank" rel="noopener" href="https://www.techbychoice.org/membership">Signup for Tech by Choice</Link>, we're the one tech community you don't today to get access to our community Slack and more!
                </Alert>
              </Box>

            <div className={classes.details}>
                <Typography className={classes.ffBarlow} variant="h5" align="left" component="h4">
                    About the Role:

                </Typography>
                  <div dangerouslySetInnerHTML={{ __html: role.description }} />
            </div>

            {role.job_skill_list.filter(skill => skill.skill_type === 'Skill').length > 0 && (
                  <>
                <div className={classes.details}>

                    <Typography className={classes.ffBarlow} variant="h5" align="left" component="h4">
                        Skills
                    </Typography>
                      {role.job_skill_list.filter(skill => skill.skill_type === 'Skill').map((skill) =>(
                          <>
                            <Chip key={skill.name} className={classes.pill} label={skill.name} />
                          </>
                      ))}
                </div>
                  </>
              )}
              {role.job_skill_list.filter(skill => skill.skill_type === 'Tool').length > 0 && (
                  <>
                    <div className={classes.details}>
                        <Typography className={classes.ffBarlow} variant="h5" align="left" component="h4">
                            Tools
                        </Typography>
                          {role.job_skill_list.filter(skill => skill.skill_type === 'Tool').map((skill) =>(
                              <>
                                <Chip key={skill.name} className={classes.pill} label={skill.name} />
                              </>
                            ))}
                    </div>
                  </>
              )}

          </div>
        )}
      </>
  );
}

export default RoleDetails;
