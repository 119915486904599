import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', Arial",
    h1: {
      fontFamily: "Barlow",
      fontWeight: "bold"
    },
    h2: {
      fontFamily: "Barlow",
      fontWeight: "bold"
    },
    h3: {
      fontFamily: "Barlow",
      fontWeight: "bold"
    },
    h4: {
      fontFamily: "Barlow",
      fontWeight: "bold"
    },
    h5: {
      fontFamily: "Barlow",
      fontWeight: "bold"
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
        h1 {
          font-family: 'Barlow', 'Open Sans', Arial;
        }

        h2 {
          font-family: 'Barlow', 'Open Sans', Arial;
        }

        h3 {
          font-family: 'Barlow', 'Open Sans', Arial;
        }  

        h4 {
          font-family: 'Barlow', 'Open Sans', Arial;
        }        
      `,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
