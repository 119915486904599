import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

const LoadingScreen = ({ height = '30vh', message = 'Loading' }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="row"
      gap={7}
      alignItems="center"
      style={{ height: height }}
    >
      <Typography style={{fontFamily: "Barlow", fontWeight: "bold"}} variant="h2" component="h1">
        {message}
      </Typography>
      <CircularProgress />
    </Box>
  );
};

export default LoadingScreen;
