import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import Box from "@material-ui/core/Box";
import TwitterIcon from '@mui/icons-material/Twitter';
import RoleDetails from "../components/RoleDetails";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {useParams} from "react-router-dom";
import {useMediaQuery, useTheme} from "@mui/material";
import Link from "@material-ui/core/Link";


const useStyles = makeStyles((theme) => ({
    resultsWrapper: {
        padding: theme.spacing(2),
    },
    pill: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    header: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    roles: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

function ResultsPage() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const {hashId} = useParams();
    const [selectedRole, setSelectedRole] = useState(null);
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState(false);
    const [imgUrl, setImgUrl] = useState('');
    const [matchPercentage, setMatchPercentage] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false); // Add dialogOpen state

    useEffect(() => {
        let timerId;

        const fetchResults = async () => {
            try {
                const url = `${process.env.REACT_APP_API_BASE_URL}api/quizzes/results/${hashId}/`;
                const res = await fetch(url);
                const data = await res.json();

                if (data && data['results']) {
                    const roles_data = data['results'];
                    const imgUrl = data['img_url'];
                    const jsonDataTest = JSON.parse(data['results']['roles']);
                    const matchJsonData = data['results']['match_percentage'];

                    if (jsonDataTest.length > 0 && roles_data) {
                        setRoles(jsonDataTest);
                        setMatchPercentage(matchJsonData);
                        setSelectedRole(jsonDataTest[0]);

                        // If imgUrl is empty, set up another fetch in 5 seconds
                        if (!imgUrl) {
                            timerId = setTimeout(fetchImg, 5000);
                        } else {
                            setImgUrl(imgUrl);
                            setDialogOpen(true);
                            window.fathom && window.fathom.trackGoal('NQLVPU9H', 0);
                        }
                    } else {
                        setError(true)
                    }
                } else {
                    setError(true)
                }
            } catch (error) {
                console.error(error);
                setError(true);
            }
        };
        const fetchImg = async () => {
            const url = `${process.env.REACT_APP_API_BASE_URL}api/quizzes/results/${hashId}/`;
            const res = await fetch(url);
            const data = await res.json();

            const imgUrl = data['img_url'];
            // If imgUrl is empty, set up another fetch in 5 seconds
            if (!imgUrl) {
                timerId = setTimeout(fetchImg, 5000);
            } else {
                setImgUrl(imgUrl);
                setDialogOpen(true);
                window.fathom && window.fathom.trackGoal('NQLVPU9H', 0);
            }
        };

        fetchResults();
        // Return a cleanup function to clear the timeout if it's still active
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, []);

    const handleCloseDialog = () => {
        setDialogOpen(false);
        window.fathom && window.fathom.trackGoal('7CXVEQV7', 0);
    };
    if( error ){
        return  (
            <Grid container className={classes.resultsWrapper}>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                     className={classes.header}>
                    <Typography align="center" variant="h4" component="h1">
                        Sorry we're having troubling finding your results
                    </Typography>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Typography variant="body1"
                                    style={{
                                        width: isMobile ? '100%' : '75%',
                                        marginTop: "16px"
                                    }}
                                    component="p"
                                    align="center">

                            If you continue to run into this issue please submit a bug to the <Link target="_blank" to='https://www.techbychoice.org'>Tech by Choice</Link> team using this <Link to='https://forms.gle/jbpbMWrEBYVxU9ev7'>link</Link>.
                        </Typography>
                    </div>

                </Box>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container className={classes.resultsWrapper}>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                     className={classes.header}>
                    <Typography align="center" variant="h4" component="h1">
                        Your Results Are In!
                    </Typography>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Typography variant="body1"
                                    style={{
                                        width: isMobile ? '100%' : '50%',
                                    }}
                                    component="p"
                                    align="left">
                            Based on your answers, we found the following top roles we think you'll enjoy. While our
                            quiz can get you pretty close to the perfect role, we can't guarantee 100% accuracy, but we
                            do know this quiz will help you get one step closer.
                        </Typography>
                    </div>

                </Box>
            </Grid>
            {roles.length > 0 ? (
                <>
                  <Grid item xs={12} sm={12} md={4}>
                        <Box display="flex" flexDirection="column" alignItems="center" className={classes.roles}>
                            {/* Add a wrapping container with horizontal scroll */}
                            <div style={{
                                 boxShadow: isMobile ? 'inset 0 0 9px rgba(0,0,0,0.1)': 0,
                                display:isMobile? 'flex' : '',
                                width:isMobile? '100%' : '',
                                paddingLeft: isMobile ? theme.spacing(2): '',
                                paddingRight: isMobile ? theme.spacing(2): '',
                                // overflow: isMobile? 'hidden': '',
                                overflowX: isMobile? 'scroll': ''}}>
                                {roles.map((role) => (
                                    <Card key={role.id} onClick={() => {
                                        setSelectedRole(role);
                                        window.fathom && window.fathom.trackGoal('ZFGDTIPO', 0);
                                    }} style={{
                                        minWidth: '200px',
                                        marginBottom: '10px',
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                        backgroundColor: selectedRole && selectedRole.id === role.id ? '#eeeeee' : 'white'
                                    }}>
                                        <CardContent>
                                            <Typography variant="h6" component="h3" align="center">
                                                {role.name}
                                            </Typography>
                                            <Typography color="textSecondary" align="center">
                                                {matchPercentage[role.id]}% Match
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <RoleDetails role={selectedRole}/>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress/>
                    </Box>
                </Grid>
            )}
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle align="center">Results Overview</DialogTitle>
                <DialogContent>
                    {/* Customize the content of the popup */}
                    <Typography variant="body1">
                        Take a screenshot or save this photo to share it with your network using the #TBCQuiz!
                    </Typography>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img style={{
                                margin: isMobile? theme.spacing(2) : '',
                                height: isMobile? '250px' : '400px',
                            }}
                             height="400px" alt="Breakdown of role matches" src={imgUrl ? imgUrl : ''}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{
                            backgroundColor: "#1d9bf0",
                            fontWeight: "bold",
                            color: "#fff",
                            textTransform: "capitalize"
                        }} startIcon={<TwitterIcon/>} 
                            target="_blank" 
                            size="small" 
                            variant="contained" 
                            alignment="left" 
                            color="secondary"
                            onClick={window.fathom.trackGoal('UHPZO46H', 0)}
                            href={`https://twitter.com/intent/tweet?text=Just%20took%20the%20%23TBCQuiz%20and%20found%20my%20tech%20role%20matches!%0A%0A-%20${matchPercentage[roles[0]?.id]}%25%20${roles[0]?.name}%20%0A-%20${matchPercentage[roles[1]?.id]}%25%20${roles[1]?.name}%20%0A-%20${matchPercentage[roles[2]?.id]}%25%20${roles[2]?.name}%20%0A-%20${matchPercentage[roles[3]?.id]}%25%20${roles[3]?.name}%20%0A-%20${matchPercentage[roles[4]?.id]}%25%20${roles[4]?.name}%20%0A%0A%F0%9F%94%97%20Get%20yours%20at%20https%3A%2F%2Fquiz.techbychoice.org%2F`}>
                        Tweet
                    </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default ResultsPage;
