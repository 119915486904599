import React, {useEffect, useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {useMediaQuery, useTheme} from "@mui/material";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import {Link} from "react-router-dom";
import LoadingScreen from "../components/Loading";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    roleName : {
        fontFamily: "Open Sans",
        // fontWeight: "Bold",
        textDecoration: "none",
        color: "#434dc6",
        transition: "color 0.5s",
        '&:hover': {
          color: theme.palette.action.active, // This will use the theme's active color for hover
          // Alternatively, you can use the darken method from SASS here to darken the color
          // color: darken("#434dc6", 0.2),
        },
    },
    tableHead: {
        fontFamily: "Barlow",
        fontWeight: "Bold",
        fontSize: "20px"
    }
}));

function ViewRolesPage() {
    const classes = useStyles();
    const theme = useTheme();
    const [roles, setRole] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const fetchRole = async () => {
            const url = process.env.REACT_APP_API_BASE_URL + 'api/quizzes/role/';
            const res = await fetch(url);
            const data = await res.json();
            setRole(data);
        };

        fetchRole();

    }, []);
    if (!roles) {
        return (
            <LoadingScreen/>
        );
    }

   const handleLinkClick = (goalId) => {
    window.fathom && window.fathom.trackGoal(goalId, 0);
  };

    return (
        <Grid container center className={classes.root}>
            <div>
                <Box>
                    {roles && (
                        <TableContainer component={Paper}>
                            <Table stickyHeader sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead} colSpan={isMobile ? 3 : 1}>Roles</TableCell>
                                        {!isMobile ? (<>
                                            <TableCell className={classes.tableHead}>Skills</TableCell>
                                            <TableCell className={classes.tableHead}>Tools</TableCell>
                                            </>
                                        ) : (<>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {roles.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell colSpan={isMobile ? 3 : 1} component="th" scope="row">
                                                <Link className={classes.roleName} onClick={() => handleLinkClick('SGLMGV9Z')} component="button" to={`/role/${row.id}`} variant="body2">
                                                    {row.name}
                                                </Link>
                                            </TableCell>

                                            {!isMobile ? (<>
                                                    <TableCell component="th" scope="row">
                                                        {row.job_skill_list
                                                            .filter((skill) => skill.skill_type === 'Skill')
                                                            .slice(0, isMobile ? 1 : 3) // Show 1 item on mobile, 3 items on larger screens
                                                            .map((skill) => (
                                                                <Chip
                                                                    style={{marginRight: '16px'}}
                                                                    variant="outlined"
                                                                    className={classes.pill}
                                                                    label={skill.name}
                                                                />
                                                            ))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.job_skill_list
                                                            .filter((skill) => skill.skill_type === 'Tool')
                                                            .slice(0, isMobile ? 1 : 3) // Show 1 item on mobile, 3 items on larger screens
                                                            .map((skill) => (
                                                                <Chip
                                                                    style={{marginRight: '16px'}}
                                                                    variant="outlined"
                                                                    className={classes.pill}
                                                                    label={skill.name}
                                                                />
                                                            ))}
                                                    </TableCell>
                                                </>)
                                                :(<>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </>)}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </div>
        </Grid>
    );
}

export default ViewRolesPage;
