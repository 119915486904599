import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Button, Card, CardContent, Typography, useMediaQuery, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {makeStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import LoadingScreen from "./components/Loading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
    tbcCard: {
        background: '#FFFEFB',
        border: '0.769668px solid #E6E6E6',
        boxShadow: '0px 3.07867px 3.07867px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px'
    },
    fontOpen: {
        // fontFamily: 'Open Sans',
        fontWeight: 'bold'
    },
    emailCard: {
        padding: theme.spacing(6),
        width: '45%',
        '& p': {
            marginBottom: theme.spacing(3)
        },
        '& form': {
            display: 'flex',
            flexDirection: 'column'
        },
        '& button': {
            marginTop: theme.spacing(3)
        }
    }
}));

const Quiz = () => {
    const classes = useStyles();
    const history = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isLoading, setIsLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [results, setResults] = useState([]);
    const [hash, setHash] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isAnswerSelected, setIsAnswerSelected] = useState(false);
    const [isJobMarketing, setIsJobMarketing] = useState(false);
    const [roles, setRoles] = useState(null);
    const [email, setEmail] = useState("");
    const [isEmailSubmitted, setEmailSubmitted] = useState(false);

    // fetch questions from API on component mount
    useEffect(() => {
        const fetchQuestions = async () => {
            const url = process.env.REACT_APP_API_BASE_URL + 'api/quizzes/1/questions/';
            const res = await fetch(url);
            const data = await res.json();
            setQuestions(data);
        };
        const fetchAnswers = async () => {
            const url = process.env.REACT_APP_API_BASE_URL + 'api/quizzes/1/answers/';
            const res = await fetch(url);
            const data = await res.json();
            setAnswers(data);
        };
        fetchQuestions();
        fetchAnswers().then(() => {
            setIsLoading(false);
        });


    }, []);

    const handleEmailSubmit = () => {
        // e.preventDefault();

        setEmailSubmitted(true);
        const url = process.env.REACT_APP_API_BASE_URL + 'api/quizzes/save-results/';
        // Tracking the button click event with Fathom
        window.fathom && window.fathom.trackGoal('YRQPNIVD', 0);
        if(isJobMarketing){
            window.fathom && window.fathom.trackGoal('JCTBQ7H5', 0);
        }

        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'email': email,
                'hash_id': hash,
                'job_marketing': isJobMarketing
            })
        })
            .then(response => response.json())
            .then(data => {
                if(data.status){
                    history(`/role/results/${hash}`);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleAnswer = (answer) => {
        setIsAnswerSelected(true);
        setSelectedAnswer(answer);

        const updatedResults = [...results];
        updatedResults[currentQuestion] = answer;
        setResults(updatedResults);
        
        window.fathom && window.fathom.trackGoal('0HUTNTNS', 0);

        setTimeout(() => {
            setIsAnswerSelected(false);
            const nextQuestion = currentQuestion + 1;
            setCurrentQuestion(nextQuestion);

            if (nextQuestion >= questions.length) {
                const url = process.env.REACT_APP_API_BASE_URL + 'api/quizzes/save-results/';
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(results)
                })
                    .then(response => response.json())
                    .then(data => {
                        setRoles(data['results']);
                        setHash(data['hash_id']);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
        }, 1000);
    };

    const goBack = () => {
        setCurrentQuestion(currentQuestion - 1);
        setSelectedAnswer(results[currentQuestion - 1]);
        
        window.fathom && window.fathom.trackGoal('ZMRVDX5G', 0);
    };

    return (
        <div>
            <Box display='flex' style={{justifyContent: "center", textAlign: "left"}}>
                {isLoading ? (
                    <>
                        <LoadingScreen/>
                    </>
                ) : (
                    <>
                        {!isEmailSubmitted && roles ? (
                            <Card className={classes.emailCard} variant="outlined">
                                <Typography variant="h5" align="center" component="h2" style={{
                                    marginBottom: '8px',
                                    color: '#C91616',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 'bold'
                                }}>
                                    Links get lost.
                                    <br/>
                                    Emails are forever.
                                </Typography>

                                <Typography style={{
                                    marginBottom: '24px'
                                }} variant="p" component="p">
                                    That's why we're sending you a copy of your results.
                                </Typography>
                                <form onSubmit={handleEmailSubmit}>
                                    <FormControl fullWidth sx={{m: 1}}>
                                        <TextField
                                            required
                                            variant="outlined"
                                            id="outlined-required"
                                            type="email"
                                            label="Email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            defaultValue="Hello World"
                                        />
                                    </FormControl>
                                    <Button type="email" variant="contained">Submit</Button>
                                    <FormControl style={{marginTop: '16px'}}>
                                      <FormControlLabel
                                          control={<Checkbox
                                              checked={isJobMarketing}
                                              onChange={e => setIsJobMarketing(e.target.checked)}
                                              name="job-marketing" />}
                                          label="Would you like to be notified when we have job opening that match the roles?" />
                                    </FormControl>

                                </form>
                            </Card>
                        ) : (
                            <>
                                {questions.length > 0 && currentQuestion < questions.length ? (
                                    <Card className={classes.tbcCard} style={{
                                        padding: '20px',
                                        width: '750px',
                                        margin: isMobile ? '24px' : '0',
                                    }}>
                                        <CardContent>
                                            <Button onClick={goBack} disabled={currentQuestion === 0}>Back</Button>
                                            <Typography variant="h5" className={classes.fontOpen} component="h2"
                                                        style={{
                                                            marginBottom: '16px',
                                                            color: '#C91616',
                                                            fontFamily: 'Open Sans',
                                                            fontWeight: 'bold'
                                                        }}>
                                                {currentQuestion + 1} / {questions.length + 1}
                                            </Typography>
                                            <Typography variant="h4" component="h2" style={{
                                                marginBottom: '32px',
                                                fontFamily: 'Barlow',
                                                fontWeight: 'bold'
                                            }}>
                                                {questions[currentQuestion].text}
                                            </Typography>
                                            <div>
                                                {answers.filter((answer) => answer.question === currentQuestion + 1)
                                                    .map((answer) => (
                                                        <Button
                                                            key={answer.id}
                                                            disabled={isAnswerSelected}
                                                            style={{marginRight: '16px', float: 'right'}}
                                                            variant={selectedAnswer === answer ? "contained" : "outlined"}
                                                            onClick={() => handleAnswer(answer)}
                                                        >
                                                            {answer.text}
                                                        </Button>
                                                    ))}
                                            </div>
                                        </CardContent>
                                    </Card>
                                ) : (
                                    <>
                                        {questions.length > 0 && (
                                            <>
                                                <LoadingScreen/>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Box>
        </div>

    );
};

export default Quiz;
