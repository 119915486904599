import React, {useEffect, useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import RoleDetails from "../components/RoleDetails";
import {useParams} from "react-router-dom";
import LoadingScreen from "../components/Loading";
import {useMediaQuery, useTheme} from "@mui/material";

function ViewRolePage() {
  const { roleId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [role, setRole] = useState(null);

  useEffect(() => {
    const fetchRole = async () => {
      const url = process.env.REACT_APP_API_BASE_URL + 'api/quizzes/role/' + roleId + '/';
      const res = await fetch(url);
      const data = await res.json();
      setRole(data);
    };

    fetchRole();

  }, []);
    if (!role){
      return (
        <LoadingScreen />
      )
  }
  if (role){
      return (
          <Grid container styles={{
              paddingRight: isMobile ? theme.spacing(5) : theme.spacing(10),
              paddingLeft: isMobile ? theme.spacing(5) : theme.spacing(10)
          }}>
              <Grid item xs={12}>
                  <RoleDetails role={role} role_id={true}/>
              </Grid>
          </Grid>
      );
  }
}

export default ViewRolePage;
