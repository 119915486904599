import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopNavBar from "./layout/TopNavBar";
import './theme'
import React from "react";
import Quiz from "./quiz";
import ViewRolePage from "./pages/ViewRolePage";
import ViewRolesPage from "./pages/ViewRolesPage";
import ResultsPage from "./pages/ResultsPage";
import LandingPage from "./pages/LandingPage";

function App() {
  return (
      <Router>
        <div className="App">
            <TopNavBar/>
            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route exact path="/quiz" element={<Quiz />} />
                <Route path="/role/:roleId" element={<ViewRolePage />} />
                <Route path="/role/" element={<ViewRolesPage />} />
                <Route path="/role/results/:hashId" element={<ResultsPage />} />
            </Routes>
        </div>
      </Router>
  );
}

export default App;
